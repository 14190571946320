import Splide from "@splidejs/splide";
import GLightbox from "glightbox";




const bootstrap = window.bootstrap;

export function init() {
	GLightbox({
		touchNavigation: true,
		loop: true,
		autoplayVideos: true
	});

	const map_click_list = document.querySelectorAll("#map-click");
	if (map_click_list) {
		map_click_list.forEach((map_click) => {
			map_click.addEventListener("click", () => {
				// shield the map on mobile for easy scrolling
				map_click.style.display = "none";
			});
		});
	}
	setupSlider();
	addHoverToCollapse();
	
	window.addEventListener("resize", setupSplashHeight);	
	window.addEventListener("load", setupSplashHeight);
	setupSplashHeight();
}

export function setupSplashHeight(){


	const splash_el = document.querySelector("#splash-wrap");

	const carousel_items = document.querySelectorAll("#splash-wrap .carousel-caption");
	let max_height = 0;
	if (splash_el && carousel_items){
		// Remove an existing max height in case it affects the height calculation
		carousel_items.forEach(item => {
			item.style.height = null;
		});
		carousel_items.forEach(item => {
			if (item.clientHeight > max_height){
				max_height = item.clientHeight;
			}
			
		});
		if (max_height){
			splash_el.style.minHeight = max_height + "px";
			// Also to stop the carousel indicators jumping we set the height of the items to be the same
			carousel_items.forEach(item => {
				item.style.setProperty("height", max_height + "px", "important");
			});
		}
		
	}
	
}

export function setupSlider(){
	var officeSlider = document.querySelectorAll(".vacancy-slider");
	
	if(officeSlider){
		for (var i = 0; i < officeSlider.length; i++ ) {
			var slider = officeSlider[i].querySelector(".slider");
			var officeSplide = null;
			let wrapper = document.querySelector(".splide-wrapper");
			
			officeSplide = new Splide( slider, {
				type   		: "slide",
				rewind    	: true,
				pagination	: true,
				arrows    	: false,
				autoplay	: true,
				// padding		: "10%",
				perPage		: 1,
				// focus  		: "center",
				fixedWidth 	: "clamp(350px, 25vw, 600px)",

				// gap			: "2rem",
				breakpoints: {
					1400: {
						padding: "0",
					},
				}
			}).mount();
				
			if(officeSplide && wrapper){
				wrapper.style.minHeight = officeSplide.Components.Elements.track.clientHeight + "px";
				window.addEventListener("resize", () => {
					wrapper.style.minHeight = officeSplide.Components.Elements.track.clientHeight + "px";
				});
			}
		}
	}
	
}

export function is_touch_enabled() {
	return ( "ontouchstart" in window ) ||  ( navigator.maxTouchPoints > 0 ) || ( navigator.msMaxTouchPoints > 0 );
}

export function closeAllCollapse(){
	const collapsable_nav_items = document.querySelectorAll(".collapse-menu > .nav-item .collapse");
	collapsable_nav_items.forEach(nav_item => {
		let bs_collapse = bootstrap.Collapse.getInstance(nav_item);
		bs_collapse.hide();
	
	});
}


export function addHoverToCollapse(){
	const nav_items = document.querySelectorAll(".collapse-menu > .nav-item");

	if (nav_items){
		nav_items.forEach(nav_item => {
			const collapse_el = nav_item.querySelector(".collapse");
			if (collapse_el){
				let bs_collapse = bootstrap.Collapse.getInstance(collapse_el);
				if (!bs_collapse) {
					bs_collapse = new bootstrap.Collapse(collapse_el, {
						toggle: false
					});
				}
				
				if (!is_touch_enabled()){
					nav_item.addEventListener("mouseenter", (e) => {
						closeAllCollapse();
						bs_collapse.show();
						e.stopPropagation();
				
					});
					nav_item.addEventListener("mouseleave", () => {
						closeAllCollapse();
					});
					nav_item.querySelector("button").style.pointerEvents = "none";
				}
			}
			else{
				if (!is_touch_enabled()){
					nav_item.addEventListener("mouseenter", () => {
						closeAllCollapse();
					});
				}
			}
		});
	
	}
	
	
}

window.addEventListener("DOMContentLoaded", init);